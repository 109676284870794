


























































































































































































































































































import { defineComponent } from '@vue/composition-api'
import TopBar from '@/components/organisms/o-top-bar.vue'
import cancelSaveDialog from '@/mixins/cancelSaveDialog'
import { mapFields } from 'vuex-map-fields'
import { mapActions, mapState, mapGetters } from 'vuex'
import { RawLocation } from 'vue-router'
import { isReadonly, userConnectedHasGroup } from '@/composables/UserGrant'
import { groupAdmin } from '@/config-constantes'

export default defineComponent({
  setup () {
    const readonly = () => isReadonly()
    return {
      readonly,
    }
  },
  name: 'TrainingType',
  components: {
    TopBar,
    CancelUpdateDialog: () => import('@/components/cancel-update-dialog.vue'),
    TimeField: () => import('@/components/base/TimeField.vue'),
    TimeSlots: () => import('@/components/molecules/timeSlots.vue'),
    TeachingAids: () => import('@/components/molecules/input/teachingAids.vue'),
  },
  data () {
    return {
      title: this.$t('training_type.form.title'),
      errors: [],
      isLoading: true,
    }
  },
  mixins: [cancelSaveDialog],
  computed: {
    ...mapFields('trainingTypeForm', [
      'trainingType.name',
      'trainingType.durationDays',
      'trainingType.titleConvention',
      'trainingType.titleAttestation',
      'trainingType.codeTeachingResourcesConvention',
      'trainingType.specificToyota',
      'trainingType.color',
      'trainingType.duration',
      'trainingType.timeSlots',
      'trainingType.pictogram',
      'trainingType.teachingAids',
      'trainingType.trainingPlanningSharepointLink',
      'trainingType.typeEvaluation',
      'trainingType.isSpecificTrappesSpe',
      'trainingType.isActive',
    ]),
    ...mapState('trainingTypeForm', {
      isTrainingTypeSubmitted: 'isSubmitted',
      isFormChanged: 'isChanged',
    }),
    ...mapGetters('navigation', {
      getLastSavedRoute: 'getLastSavedRoute',
    }),
    idTrainingType () {
      return this.$route.params.idTrainingType || null
    },
  },
  async created () {
    await this.init()
  },
  methods: {
    async init () {
      if (this.idTrainingType) {
        await this.loadTrainingTypeById(
          this.idTrainingType,
        )
        this.isLoading = false
      } else {
        this.reset()
        this.isLoading = false
      }
    },
    ...mapActions('trainingType', {
      loadAllTrainingTypes: 'load',
    }),
    ...mapActions('trainingTypeForm', {
      save: 'save',
      loadTrainingTypeById: 'loadById',
      reset: 'reset',
    }),
    ...mapActions('user', {
      sendSuccessMessage: 'sendSuccessMessage',
    }),
    async onSubmit () {
      const isValidatedForm = await (this as any).isValidatedForm(
        this.$refs.validationObserver,
      )
      if (isValidatedForm) {
        await this.save(this.trainingType)
        await this.loadAllTrainingTypes({
          pagination: false,
        })
        await this.sendSuccessMessage('training_type.form.saved')
        this.returnPrevious()
      }
    },
    cancel () {
      this.returnPrevious()
    },
    returnPrevious () {
      if (this.getLastSavedRoute) {
        this.$router.push(this.getLastSavedRoute as RawLocation)
      } else {
        this.$router.push({ name: 'TrainingType List' })
      }
    },
  },
})
